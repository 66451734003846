<script lang="ts" setup>
const { locale } = useI18n();
</script>

<template>
  <video width="1920" height="1080" controls class="w-full h-full rounded-lg">
    <source
      :src="`https://db.vendeo.io/storage/v1/object/public/videos/${locale}.mp4`"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>
</template>
