import { useGeneralStore } from "~/stores/generalStore";
export const useLogout = async () => {
  
  const generalStore = useGeneralStore();
  const { auth } = useSupabaseClient();
  const { error } = await auth.signOut();

  if (error) {
    console.error(error);
    return;
  }
  generalStore.setAccount("");
  generalStore.setPlan("");
  await navigateTo("/");
};
