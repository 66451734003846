<script lang="ts" setup>
const user = useSupabaseUser();
</script>

<template>
  <div class="flex justify-center flex-row gap-3 items-center">
    <HelpCenter v-if="user" class="md:block hidden" />
    <ThemeSwitcher />
    <LanguageSelector />
  </div>
</template>
